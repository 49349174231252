import React from 'react';
import { Layout } from '@/components/Layout';
import { useLocalStorage } from 'react-use';

const Error404 = (props) => {
    const [checkoutState, setCheckoutState, clearCheckoutState] = useLocalStorage("checkout-state");

    React.useEffect(() => {
        // clear the local storage for checkout on a 404 - just to be safe
        clearCheckoutState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <div className='flex justify-center items-center w-full bg-white h-[100vh]'>
                <h1
                    className='mr-5 pr-6 text-2xl font-medium leading-[49px]'
                >
                404
                </h1>
                <div className='text-left leading-[49px] h-[49px] flex items-center'>
                    <h2 className='text-sm font-normal leading-[49px] m-0 p-0'>
                    This page could not be found.
                    </h2>
                </div>
            </div>
        </Layout>
    );
};

export default Error404;
